<script setup>

const status = ref(false)
const bibliaSk = useCookie("biblia_sk", {
    maxAge: 31546000
})

onMounted(() => {
    
    if(typeof bibliaSk.value !== 'undefined' && typeof bibliaSk !== 'undefined'){
        if(bibliaSk.value.donationBar){
            setTimeout(() => {
                status.value = true
            }, 10000);
        }
    }

});

function closeDonation(){
    status.value = false
    
    if(typeof bibliaSk.value !== 'undefined'){
        bibliaSk.value.donationBar = false
    }
}

</script>
<template>
    <Transition name="fade-from-left">
    <div v-if="status" :class="['hidden lg:block fixed top-1/3 pl-2']">
        <span class="text-xs uppercase text-green-500 font-bold">Podporte nás</span>
        <div @click="toggleSidebar('qr')" class="bg-green-50 border border-green-300 p-2 my-2 group cursor-pointer hover:bg-green-200 transition ease-in-out duration-75">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block ml-2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
            </svg>
        </div>
        <NuxtLink to="https://biblickaspolocnost.darujme.sk/biblia-sk/" target="_blank" class="bg-green-50 border block border-green-300 p-2 my-2 group cursor-pointer hover:bg-green-200 transition ease-in-out duration-75">
            <span class="font-bold">5 € </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block ml-2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
            </svg>
        </NuxtLink>
        <NuxtLink to="https://biblickaspolocnost.darujme.sk/biblia-sk/" target="_blank" class="bg-green-50 border block border-green-300 p-2 my-2 group cursor-pointer hover:bg-green-200 transition ease-in-out duration-75">
            <span class="font-bold">15 € </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block ml-2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
            </svg>
        </NuxtLink>
        <NuxtLink to="https://biblickaspolocnost.darujme.sk/biblia-sk/" target="_blank" class="bg-green-50 border block border-green-300 p-2 mt-2 group cursor-pointer hover:bg-green-200 transition ease-in-out duration-75">
            <span class="font-bold">30 € </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block ml-2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
            </svg>
        </NuxtLink>
        <span @click="closeDonation()" class="text-xs cursor-pointer">Zatvoriť</span>
    </div>
    </Transition>

    <QrSidebar/>

</template>

<style>

.fade-from-left-enter-active {
  animation: fade-in 0.5s;
}
.fade-from-left-leave-active {
  animation: fade-in 0.5s reverse;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  50% {
    opacity: 1;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

</style>