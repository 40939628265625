<script setup>

/**
 *  Imports
 */
import { useGlobalStore } from '@/store/global'

/**
 *  Composables
 */
const store = useGlobalStore()

</script>
<template>
    <div @click="toggleSidebar('menu')" class="">
        <div :class="['relative py-4 px-3 md:px-4 cursor-pointer', store.getSidebarType == 'menu' ? '' : 'hover:bg-blue-600']">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
            </svg>
            <span class="lg:ml-2 uppercase hidden lg:inline-block">Menu</span>
        </div>
    </div>
</template>