<script setup>

const bibliaSk = useCookie('biblia_sk', {
    maxAge: 31546000
})

const showDonation = computed(() => bibliaSk.value ? bibliaSk.value.donation : true)

function handleClose(){
    bibliaSk.value.donation = false
}

</script>
<template>
    <div v-if="showDonation" @click="handleClose()" class="fixed border border-green-400 bg-green-200 hover:bg-green-300 z-20 shadow-lg sm:mx-auto left-0 right-0 max-w-xl cursor-pointer bottom-2 mx-2 rounded text-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-red-500 inline-block mr-2">
            <path d="M9.653 16.915l-.005-.003-.019-.01a20.759 20.759 0 01-1.162-.682 22.045 22.045 0 01-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 018-2.828A4.5 4.5 0 0118 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 01-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 01-.69.001l-.002-.001z" />
        </svg>
        <b>Podporte</b> fungovanie stránky Biblia.sk <NuxtLink class="text-blue-500 underline py-2 inline-block" to="https://www.biblickaspolocnost.sk/podpora/" target="_blank">TU.</NuxtLink>
    </div>
</template>