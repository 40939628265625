<script setup>

/**
 *  Imports
 */
import { useGlobalStore } from '@/store/global'

/**
 *  Composables
 */
const store = useGlobalStore()

</script>

<template>
    <Transition name="fade">
        <div v-if="store.getSidebarType == 'qr'" @click="toggleSidebar('qr')" :class="['bg-gray-100 z-50 opacity-80 fixed left-0 right-0 top-0 bottom-0 mx-auto']"></div>
    </Transition>

    <Transition name="slide-fade">
    <div v-if="store.getSidebarType == 'qr'" class="fixed bg-white top-0 bottom-0 right-0 w-full md:w-96 overflow-auto text-center border-l z-50">

        <h2 class="my-4 uppercase text-xs text-gray-700 relative">
            <svg @click="toggleSidebar('qr')" class="absolute lg:hidden cursor-pointer left-2 text-gray-400 top-0 bottom-0 my-auto w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
            Podpora
        </h2>

        <h3 class="text-lg font-bold mt-4">Rýchla platba pomocou QR kódu</h3>
        
        <p class="p-4">Aby ste nemuseli prepisovať platobné údaje, vyplnili sme ich za vás. Stačí jednoducho naskenovať QR kód vo vašej <b>platobnej aplikácii</b> a automaticky sa vám vyplní IBAN a poznámka "dar". Vy už zadáte len sumu.</p>

        <NuxtImg preload class="mx-auto m-4 p-4 border-2 border-green-300" width="267" height="267" src="/qr.jpg" alt="QR kód"/>

        <hr class="mt-4">

        <button @click="toggleSidebar('qr')" class="my-6 text-gray-500 hover:text-black">Zatvoriť</button>

    </div>
    </Transition>

</template>