<script setup>

import { useMessageStore } from '@/store/message'

const message = useMessageStore()

const classObject = computed(() => ({
  'bg-green-100 border-green-400': message.type === 'success',
  'bg-red-100 border-red-400': message.type === 'error',
  'bg-yellow-100 border-yellow-400': message.type === 'info',
}))

</script>
<template>
    <Transition name="slide-fade-from-bottom">
        <div 
            v-if="message.status == 1"
            @click="message.removeMessage()" 
            class="fixed border-2 font-bold text-center bottom-4 mx-auto right-4 max-w-md z-50 left-4 p-4 rounded shadow-xl cursor-pointer"
            :class="classObject"
        >
            {{ message.content }}
        </div>
    </Transition>
</template>
<style>
    .slide-fade-from-bottom-enter-active {
        transition: all 0.1s ease-out;
    }

    .slide-fade-from-bottom-leave-active {
        transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-from-bottom-enter-from,
    .slide-fade-from-bottom-leave-to {
        transform: translateY(10px);
        opacity: 0;
    }
</style>